/**
 * This mixin is currently loaded on all page components. It is a good place for things that you want to
 * be part of each page component. (e.g. checking whether content should be shown or announcing a page load)
 */
import scrollBehavior from '~/app/router.scrollBehavior.js'

export default {
  computed: {
    showContent () {
      const allowedRoutes = this.$store.state.allowedRoutes
      const routeAllowed = allowedRoutes.includes(this.$route.fullPath)
      const showContent = this.$initAuth || this.$store.state.isAuth || routeAllowed
      return this.$initStudent ? this.content?.allow_student_access : showContent
    },
    description () {
      return this.content.description
    },
    main_image () {
      if (this.content.main_image === undefined || this.content.main_image.data === undefined) {
        return '/images/hero_brutus.jpg'
      }
      const original = this.content.main_image.data.image.data.uri.url
      const host = new URL(process.env.DRUPAL_AUTH_URL).host
      const header = original.replace('/sites/default/files/', `//${host}/sites/default/files/styles/header_large/public/`)
      return header
    },
    containerWidth () {
      if ((this.$route.path === '/community') || (this.$route.path === '/questions') || (this.$route.path === '/assets')) {
        return 'narrow'
      } else if (this.$route.path === '/') {
        return 'wide'
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$announcer.set(this.content ? `${this.content.title} page has loaded.` : `${document.title} has loaded.`)
    this.$nextTick(() => {
      const links = document.querySelectorAll('main .main a')
      links.forEach(l => l.addEventListener('click', clickListener.bind(this)))
    })
    if (this.$route.hash) {
      this.$nextTick(async () => {
        const scrollTo = await scrollBehavior(this.$route)
        window.scrollTo(0, scrollTo.y)
      })
    }
  },
  beforeDestroy () {
    const links = document.querySelectorAll('main .main a')
    links.forEach(l => l.removeEventListener('click', clickListener))
  }
}

function clickListener (e) {
  if (!e.isTrusted) { return null }
  const linkURL = new URL(e.target.href)
  const pageURL = new URL(document.location.href)
  const samePage = pageURL.pathname === linkURL.pathname
  if (samePage) {
    const route = {
      path: linkURL.pathname,
      hash: linkURL.hash
    }
    e.preventDefault()
    // const scrollTo = await scrollBehavior(route)
    // window.scrollTo(0, scrollTo.y)
    this.$router.replace(linkURL.pathname + (linkURL.hash ? linkURL.hash : ''))
  }
}
