// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heroImage_Yxgc9{margin:0 0 24px}@media(min-width:768px){.heroImage_Yxgc9{margin:64px 0 48px}}.intro_ec87r{color:#212325;font-size:1.5rem;font-weight:600;line-height:2.25rem;margin:0 0 24px}.intro_ec87r,.question_3O5lJ{font-family:\"BuckeyeSans\",\"HelveticaNeue\",\"Helvetica\",\"Arial\",sans-serif;padding:0}.question_3O5lJ{font-size:1.125rem;line-height:1.5rem;margin:0 0 64px}.questionText_M7jvv{font-weight:700}.questionText_M7jvv:before{color:#ba0c2f;content:\"\";font-family:bux-icons;font-size:20px;font-weight:400;vertical-align:sub}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroImage": "heroImage_Yxgc9",
	"intro": "intro_ec87r",
	"question": "question_3O5lJ",
	"questionText": "questionText_M7jvv"
};
module.exports = ___CSS_LOADER_EXPORT___;
