<template>
  <div
    v-show="showContent"
  >
    <div>
      <div id="content" class="outer-wrapper">
        <div class="container">
          <div class="bux-grid">
            <div class="bux-grid__cell bux-grid__cell--12 bux-grid__cell--1@lg" />
            <div class="bux-grid__cell bux-grid__cell--12 bux-grid__cell--10@lg">
              <img src="images/hero-home-seal.jpg" alt="" :class="$style.heroImage">
              <h2 class="main-header">Get Started</h2>
              <p :class="$style.intro">
                The Ohio State University’s brand guide is frequently expanded to meet the needs of our community creating various materials and experiences for our audiences; and revised to reflect changing industry best practices and Ohio State processes and policies. Always reference brand.osu.edu as your source for the most current standards, tools and resources.
              </p>
              <div :class="$style.question">
                <span :class="$style.questionText"> Questions?</span> We’re here to help. Reach out at <a href="mailto:brandcenter@osu.edu" class="bux-link">brandcenter@osu.edu.</a>
              </div>
            </div>
            <div class="bux-grid__cell bux-grid__cell--12 bux-grid__cell--1@lg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable nuxt/no-this-in-fetch-data */
import BaseIcon from '@umarketing/osu-design-system'
import showContentMixin from '~/lib/mixinShowContent.js'

export default {
  components: BaseIcon,
  mixins: [showContentMixin],
  fetchOnServer: false,
  metaInfo () {
    return {
      title: this.isAuth ? 'Employee Home – Brand Guidelines | Ohio State' : 'Home – Brand Guidelines | The Ohio State University',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Learn about the Ohio State brand, guidelines and how to apply concepts.'
        }
      ]
    }
  },
  data () {
    return {
      videostate: 'playing'
    }
  },
  methods: {
    videocontrol () {
      const vid = document.getElementById('herovideo')
      const pauseIcon = document.getElementById('pauseIcon')

      if (this.videostate === 'playing') {
        this.videostate = 'paused'
        vid.pause()
        pauseIcon.style.visibility = 'visible'
      } else {
        this.videostate = 'playing'
        vid.play()
        pauseIcon.style.visibility = 'hidden'
      }
    }
  },
  middleware (context) {
    const { $initStudent, redirect } = context
    if ($initStudent) {
      return redirect('/brand-guidelines')
    }
  }
}
</script>

<style lang="scss">
/*
a:focus, button:focus {
  outline: 3px solid #0079c1;
}
p a,
p a:link,
p a:visited,
p a:active,
[class^='main'] ul a,
[class^='main'] ul a:link,
[class^='main'] ul a:visited,
[class^='main'] ul a:active {
  border-bottom: 1px solid #b00;
  background-image: linear-gradient(120deg,#ddd 0,#ddd 100%);
  background-repeat: no-repeat;
  background-size: 100% 0;
  background-position: 0 100%;
  color: #222;
  position: relative;
  -webkit-transition: background-size 125ms ease-in;
  transition: background-size 125ms ease-in;
  text-decoration: none;
}
p a:hover,
[class^='main'] ul a:hover {
  border-color: #b00;
  background-size: 100% 100%;
  text-decoration: none;
}
*/

/* Global style overrides */
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
ul.bux-list-ul li:before {
  content: "" / "";
}
p {
  line-height: 1.5;
}
h2[class^='BaseHeading__heading'] {
  margin-top: 6rem;
}
h3[class^='BaseHeading__heading'] {
  margin-top: 4rem;
}
h4[class^='BaseHeading__heading'] {
  margin-top: 3rem;
}
[class^='group'] ol {
  line-height: 1.5rem;
}
.noTopMargin h2,
.noTopMargin h3,
.noTopMargin h4,
.noTopMargin h5,
.noTopMargin h6 {
  margin-top: 0 !important;
}
.addTopMargin {
  margin-top: 4rem !important;
}
.addBottomMargin {
  margin-bottom: 4rem !important;
}
.addBottomMargin-s {
  margin-bottom: $space-s !important;
}
.accordionBorderTop {
  border-top: 2px solid $color-gray-lightest !important;
}
.mediaBorder {
  img {
    border: 1px solid $color-gray-light;
  }
}
[class^='BaseColumns__row'] {
  width: 99.98%;
}
[class^='BaseLink__base-link'] {
  align-self: flex-start;
  display: inline-flex;
  position: relative;
}
[class^='BaseLink__icon'] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[class^='BaseTextarea__form'] textarea {
  font-family: Arial, sans-serif;
}
.ratio16x9 {
  padding: $ratio-16x9 0 0 !important;
}
.ratio3x2 {
  padding: $ratio-3x2 0 0 !important;
}
.ratio4x3 {
  padding: $ratio-4x3 0 0 !important;
}
.ratio8x5 {
  padding: $ratio-8x5 0 0 !important;
}
.paddingTop4 {
  @media (min-width: 992px) {
    padding-top: 4px;
  }
}
.marginRight32 {
  margin-right: 32px;
}
.alignRight {
  @media (min-width: 992px) {
    text-align: right;
  }
}
.noTextTransform,
.noTextTransform [class^='BaseButton__buttonText'] {
  text-transform: none;
}
.autoHeight {
  height: auto;
}
[class^='BaseCard__card-'] {
  position: relative;
}
[class^='GalleryImages__header'] [class^='GalleryImages__view-all'] {
  left: calc(100% - 190px);
}
.bux-h3,
.bux-h3 h2,
.bux-h3 h4,
.bux-h3 h5,
.bux-h3 h6 {
  font-size: 1.375rem !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
}
.bux-h4,
.bux-h4 h2,
.bux-h4 h3,
.bux-h4 h5,
.bux-h4 h6 {
  font-size: 1.125rem !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
}
.bux-h5,
.bux-h5 h2,
.bux-h5 h3,
.bux-h5 h4,
.bux-h5 h6 {
  font-size: 1rem !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
}
.bux-h6,
.bux-h6 h2,
.bux-h6 h3,
.bux-h6 h4,
.bux-h6 h5 {
  font-size: 1rem !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
}
.heading {
  margin-bottom: 16px !important;
}
.textUppercase {
  text-transform: uppercase;
}
.responsive-image figcaption {
  border-bottom: 2px solid #eff1f2;
  height: auto;
  left: 0;
  overflow: visible;
  padding: 8px 0;
  position: relative;
  top: auto;
  width: auto;
}
.responsive-image figcaption:empty {
  display: none;
}
.js-focus-visible brand-email-signature-generator.focus-visible,
.js-focus-visible brand-email-signature-generator-student.focus-visible {
  outline: none !important;
  border: none !important;
}
</style>
<style scoped lang="scss">
body {
  font-size: 16px;
}

.outer-wrapper {
  position: relative;
}

.contentContainer {
  margin: 0 0 48px;
  position: relative;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 992px) {
    padding: 0 80px;
  }

  @media (min-width: 1200px) {
    padding: 0 190px;
  }
}

h1,
.main-header {
  font-family: "BuckeyeSerif";
  font-size: 2.625rem;
  font-weight: 900;
  line-height: 3.125rem;
  margin: 0 0 12px;
  padding: 0;
}
</style>

<style lang="scss" module>
  .heroImage {
    margin: 0 0 24px;

    @media (min-width: 768px) {
      margin: 64px 0 48px;
    }
  }
  .intro {
    color: #212325;
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    margin: 0 0 24px;
    padding: 0;
  }
  .question {
    font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0 0 64px;
    padding: 0;
  }
  .questionText {
    font-weight: 700;
  }
  .questionText::before {
    color: $color-scarlet;
    /*content: "\f30f";*/
    content: '\f316';
    font-family: bux-icons;
    font-size: 20px;
    font-weight: normal;
    vertical-align:sub;
  }
</style>
